/* eslint-disable */
import React from 'react';
import { useTheme } from '@mui/styles';

import { useEffect, useState, useRef } from 'react';
import { map } from './core/MapView';

const ReplayLiveRoute = ({ positions, index }) => {
    const id = 'memby';
    const theme = useTheme();
    useEffect(() => {
        map.addSource(id, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: [],
                },
            },
        });
        map.addLayer({
            source: id,
            id,
            type: 'line',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
            },
            paint: {
                'line-color': theme.palette.colors.geometry,
                'line-width': 3,
            },
        });

        return () => {
            if (map.getLayer(id)) {
                map.removeLayer(id);
            }
            if (map.getSource(id)) {
                map.removeSource(id);
            }
        };
    }, []);

    useEffect(() => {
        map.getSource(id).setData({
            type: 'Feature',
            geometry: {
                type: 'LineString',
                coordinates: positions.slice(0, index + 1).map((item) => [item.longitude, item.latitude]),
            },
        });
    }, [positions, index]);
    return null;
}

export default ReplayLiveRoute
