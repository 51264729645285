/*eslint-disable */
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { map } from './core/MapView';
import { getStatusColor } from '../common/util/formatter';
import usePersistedState from '../common/util/usePersistedState';

const MapReplayIcon = ({ positions, onClick, showStatus }) => {
    const id = 'positions';
    const clusters = `${id}-clusters`;
    // const clusters = 'clusters';

    const devices = useSelector((state) => state.devices.items);

    const [mapCluster] = usePersistedState('mapCluster', true);

    const createFeature = (devices, position) => {
        const device = devices[position.deviceId];
        return {
            id: position.id,
            deviceId: position.deviceId,
            name: device.name,
            category: device.category || 'default',
            color: 'neutral',
            rotation: position.course,
        };
    };

    const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
    const onMouseLeave = () => map.getCanvas().style.cursor = '';

    const onMarkerClick = useCallback((event) => {
        const feature = event.features[0];
        if (onClick) {
            onClick(feature.properties.id, feature.properties.deviceId);
        }
    }, [onClick]);

    const onClusterClick = useCallback((event) => {
        const features = map.queryRenderedFeatures(event.point, {
            layers: [clusters],
        });
        const clusterId = features[0].properties.cluster_id;
        map.getSource(id).getClusterExpansionZoom(clusterId, (error, zoom) => {
            if (!error) {
                map.easeTo({
                    center: features[0].geometry.coordinates,
                    zoom,
                });
            }
        });
    }, [clusters]);

    useEffect(() => {
        map.addSource(id, {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            cluster: mapCluster,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        });

        map.addLayer({
            id,
            type: 'symbol',
            source: id,
            filter: ['!', ['has', 'point_count']],
            layout: {
                'icon-image': '{category}-{color}',
                'icon-allow-overlap': true,
                'text-field': '{name}',
                'text-allow-overlap': true,
                'text-anchor': 'bottom',
                'text-offset': [0, -2],
                'text-font': ['Roboto Regular'],
                'text-size': 12,
                'icon-rotate': ['get', 'rotation'],
                'icon-rotation-alignment': 'map',
                'icon-pitch-alignment': 'auto',
            },
            paint: {
                'text-halo-color': 'white',
                'text-halo-width': 2,
            },
        });
        // map.addLayer({
        //     id: 'meroText',
        //     type: 'symbol',
        //     source: id,
        //     filter: ['!', ['has', 'point_count']],
        //     layout: {
        //         'icon-image': 'nameTag',
        //         'icon-allow-overlap': true,
        //         'text-field': '{name}',
        //         'text-allow-overlap': true,
        //         'text-anchor': 'bottom',
        //         'text-offset': [0, -2],
        //         'text-font': ['Roboto Regular'],
        //         'text-size': 12,
        //         // 'icon-rotate': ['get', 'rotation'],
        //         // 'icon-rotation-alignment': 'map',
        //         // 'icon-pitch-alignment': 'auto',
        //         'icon-offset': [0, -30],
        //         "icon-size": 1.5,
        //     },
        //     paint: {
        //         'text-halo-color': 'white',
        //         // 'text-halo-width': 100,
        //     },
        // });
        map.addLayer({
            id: clusters,
            type: 'circle',
            source: id,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#ffffff',
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40,
                ],
                'circle-stroke-color': [
                    'step',
                    ['get', 'point_count'],
                    '#51bbd6',
                    20,
                    '#f1f075',
                    50,
                    '#f28cb1',
                ],
                'circle-stroke-width': 10,
            },
        });
        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: id,
            filter: ['has', 'point_count'],
            layout: {
                'icon-allow-overlap': true,
                'text-allow-overlap': true,
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Roboto Regular'],
                'text-size': 14,
            },
        });

        map.on('mouseenter', id, onMouseEnter);
        map.on('mouseleave', id, onMouseLeave);
        map.on('mouseenter', clusters, onMouseEnter);
        map.on('mouseleave', clusters, onMouseLeave);
        map.on('click', id, onMarkerClick);
        map.on('click', clusters, onClusterClick);

        return () => {
            map.off('mouseenter', id, onMouseEnter);
            map.off('mouseleave', id, onMouseLeave);
            map.off('mouseenter', clusters, onMouseEnter);
            map.off('mouseleave', clusters, onMouseLeave);
            map.off('click', id, onMarkerClick);
            map.off('click', clusters, onClusterClick);

            if (map.getLayer(id)) {
                map.removeLayer(id);
            }
            if (map.getLayer('meroText')) {
                map.removeLayer('meroText');
            }
            if (map.getLayer(clusters)) {
                map.removeLayer(clusters);
            }
            if (map.getLayer('cluster-count')) {
                map.removeLayer('cluster-count');
            }
            if (map.getSource(id)) {
                map.removeSource(id);
            }
        };
    }, [mapCluster, clusters, onMarkerClick, onClusterClick]);

    useEffect(() => {
        map.getSource(id).setData({
            type: 'FeatureCollection',
            features: positions.filter((it) => devices.hasOwnProperty(it.deviceId)).map((position) => ({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [position.longitude, position.latitude],
                },
                properties: createFeature(devices, position),
            })),
        });
    }, [devices, positions]);

    return null;
};

export default MapReplayIcon;
