/* eslint-disable */
import { useTheme } from '@mui/styles';

import maplibregl from 'maplibre-gl';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { map } from './core/MapView';
import stopPNG from '../resources/images/stopFlag.png';
import startFlag from '../resources/images/startFlag.png';

const MapRoutePath = ({ positions }) => {
  const id = 'replay';
  const pId = 'point';

  const theme = useTheme();

  const reportColor = useSelector((state) => {
    const position = positions.find(() => true);
    if (position) {
      const attributes = state.devices.items[position.deviceId]?.attributes;
      if (attributes) {
        const color = attributes['web.reportColor'];
        if (color) {
          return color;
        }
      }
    }
    return theme.palette.colors.geometry;
  });

  useEffect(() => {
    map.addSource(id, {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      source: id,
      id,
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 4,
        'line-color': '#126cc0',
      },
    });

    return () => {
      if (map.getLayer(id)) {
        map.removeLayer(id);
      }
      if (map.getSource(id)) {
        map.removeSource(id);
      }
    };
  }, []);


  useEffect(() => {
    const coordinates = positions.map((item) => [item.longitude, item.latitude]);
    map.getSource(id).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
      properties: {
        color: reportColor,
      },
    });
    if (coordinates.length) {
      // const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new maplibregl.LngLatBounds(coordinates[0], coordinates[0]));
      // const canvas = map.getCanvas();
      // map.fitBounds(bounds, {
      //   padding: Math.min(canvas.width, canvas.height) * 0.1,
      //   duration: 1000,
      // });
      const start = coordinates[0];
      map.loadImage(startFlag, (error, image) => {
        map.addImage('startFlag', image);
        map.addSource('dhoom', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [start[0], start[1]],
                }
              }
            ]
          }
        });
        map.addLayer({
          id: 'dhooms',
          type: 'symbol',
          source: 'dhoom',
          layout: {
            'icon-image': 'startFlag',
            'icon-size': 1.5,
            'icon-allow-overlap': true,
          },
        });
      });
      const end = coordinates[coordinates.length - 1];
      map.loadImage(stopPNG, (error, image) => {
        map.addImage('stopPng', image);
        map.addSource(pId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [end[0], end[1]],
                }
              }
            ]
          }
        });
        map.addLayer({
          id: 'points',
          type: 'symbol',
          source: 'point',
          layout: {
            'icon-image': 'stopPng',
            'icon-size': 1.5,
            'icon-allow-overlap': true,
          },
          paint: {
            'icon-color': 'green',
          },
        });
      });

    }
    return () => {
      if (map.getLayer('points')) {
        map.removeLayer('points');
      }
      if (map.getLayer('dhooms')) {
        map.removeLayer('dhooms');
      }
      if (map.getSource('dhoom')) {
        map.removeSource('dhoom');
      }

      if (map.getSource(pId)) {
        map.removeSource(pId);
      }
    };
  }, [positions, reportColor]);

  return null;
};

export default MapRoutePath;
