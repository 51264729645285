/* eslint-disable */

import { useEffect } from 'react';


// import { useSelector } from 'react-redux';
import dimensions from '../common/theme/dimensions';
import { map } from './core/MapView';
import usePersistedState from '../common/util/usePersistedState';

const MapReplayPosition = ({ position }) => {


    const [mapFollow] = usePersistedState('mapFollow', true);
    // console.log(position[0].longitude, position[0].latitude)
    useEffect(() => {
        if ((mapFollow) && position) {
            map.easeTo({
                center: [position[0].longitude, position[0].latitude],
                zoom: 16,
                offset: [0, -dimensions.popupMapOffset / 7],
            });
        }
    });

    return null;
};

export default MapReplayPosition;
