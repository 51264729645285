import React from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import logoPNG from '../resources/images/trackonLogoWhite.png';
import androidQR from '../resources/images/androidQR.png';
import iosQR from '../resources/images/iosQR.png';
import Cars from '../resources/images/Cars.png';
import { useTranslation } from '../common/components/LocalizationProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.contrastText,
    background: '#2d408a',
    paddingBottom: theme.spacing(5),
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('lg')]: {
      width: theme.dimensions.sidebarWidthTablet,
    },
    [theme.breakpoints.down('sm')]: {
      width: '0px',
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '90%',
    width: '100%',
  },
  logo: {
    height: '40px',
    width: '193px',
  },
  catchphrase: {
    margin: '0',
    marginBottom: 'auto',
    textAlign: 'center',
  },
  carContainer: {
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  secureText: {
    fontSize: '25px',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: '700',
    padding: '0 20px',

  },
  downloadContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '300px',
    justifySelf: 'end',
  },
  downloadQR: {
    width: '100px',
    heigh: '100px',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 25, 0, 0),
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
  },
  attribution: {
    position: 'fixed',
    bottom: theme.spacing(1),
    right: theme.spacing(1.5),
    fontSize: 'x-small',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const t = useTranslation();

  return (
    <>
      <main className={classes.root}>
        <div className={classes.sidebar}>
          {!useMediaQuery(theme.breakpoints.down('lg')) && (
            // <svg height="64" width="240">
            //   <use xlinkHref={`${logoSvg}#img`} />
            // </svg>
            <div className={classes.logoContainer}>
              <div className={classes.logoCatch}>
                <img className={classes.logo} src={logoPNG} alt="logo-trackon" />
                <p className={classes.catchphrase}>ensure Your security</p>
              </div>
              <div className={classes.carContainer}>
                <p className={classes.secureText}>{t('vehicleSecureText')}</p>
                <img className={classes.cars} src={Cars} alt="trackon-banner-cars" />
              </div>
              <p>{t('downloadDownload')}</p>
              <div className={classes.downloadContainer}>
                <img className={classes.downloadQR} src={androidQR} alt="android-qr" />
                <img className={classes.downloadQR} src={iosQR} alt="ios-qr" />
              </div>
            </div>
          )}
        </div>
        <Paper className={classes.paper}>
          <form className={classes.form}>
            {children}
          </form>
        </Paper>
      </main>
      {/* <div className={classes.attribution}>
        Powered by&nbsp;
        <a href="https://www.traccar.org/">Traccar GPS Tracking System</a>
      </div> */}
    </>
  );
};

export default LoginLayout;
