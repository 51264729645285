/*eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSound } from 'use-sound';

import { positionsActions, devicesActions, sessionActions } from './store';
import { useEffectAsync } from './reactHelper';
import { useTranslation } from './common/components/LocalizationProvider';
import { snackBarDurationLongMs } from './common/util/duration';
import usePersistedState from './common/util/usePersistedState';

import alarm from './resources/alarm.mp3';

const SocketController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation();

  const authenticated = useSelector((state) => !!state.session.user);
  const devices = useSelector((state) => state.devices.items);

  const socketRef = useRef();

  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);

  const [soundEvents] = usePersistedState('soundEvents', []);
  const [soundAlarms] = usePersistedState('soundAlarms', ['sos']);
  const [play] = useSound(alarm);

  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socketRef.current = socket;

    socket.onopen = () => {
      dispatch(sessionActions.updateSocket(true));
    };

    socket.onerror = async () => {
      dispatch(sessionActions.updateSocket(false));
      const devicesResponse = await fetch('/api/devices');
      if (devicesResponse.ok) {
        dispatch(devicesActions.update(await devicesResponse.json()));
      }
      const positionsResponse = await fetch('/api/positions', {
        headers: { 'Content-Type': 'application/json' },
      });
      if (positionsResponse.ok) {
        dispatch(positionsActions.update(await positionsResponse.json()));
      }
      setTimeout(() => connectSocket(), 60000);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(positionsActions.update(data.positions));
      }
      if (data.events) {
        setEvents(data.events);
      }
    };
  };
  // useEffectAsync(async () => {
  //   if (id) {
  //     const response = await fetch(`/api/positions?id=${id}`);
  //     if (response.ok) {
  //       const positions = await response.json();
  //       if (positions.length > 0) {
  //         setItem(positions[0]);
  //       }
  //     } else {
  //       throw Error(await response.text());
  //     }
  //   }
  // }, [id]);

  // const deviceName = id => ((state) => {
  //   if (item) {
  //     const device = state.devices.items[id];
  //     if (device) {
  //       return device.name;
  //     }
  //   }
  //   return null;
  // });

  const getDeviceName = (id) => {
    const device = devices[id]
    return (device.name)
  }
  // console.log(devices)
  // const getDeviceName = (devid) => {
  //   useSelector((state) => {
  //     const device = state.devices.items[devid];
  //     if (device) {
  //       return device.name;
  //     }
  //     return null;
  //   });
  // }
  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      };
    }
    const response = await fetch('/api/session');
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
    } else {
      navigate('/login');
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    setNotifications(events.map((event) => ({
      id: event.id,
      message: event.attributes.alarm ? `${event.type}: ${event.attributes.alarm}` : event.type,
      show: true,
      devicename: getDeviceName(event.deviceId),
    })));
  }, [events, devices, t]);

  useEffect(() => {
    events.forEach((event) => {
      if (soundEvents.includes(event.type) || (event.type === 'alarm')) {
        new Audio(alarm).play();
        // play();
      }
    });
  }, [events, soundEvents, soundAlarms]);
  // console.log(events)
  const snackMessage = (message) => {
    switch (message) {
      case 'ignitionOn':
        return 'Ignition On';
      case 'alarm: vibration':
        return 'Alarm: Vibration';
      default:
        return message;
    }
  }
  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          open={notification.show}
          message={`${notification.devicename}: ${snackMessage(notification.message)}`}
          autoHideDuration={snackBarDurationLongMs}
          onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
        />
      ))}
    </>
  );
};

export default connect()(SocketController);
