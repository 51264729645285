/*eslint-disable */
import palette from '../../common/theme/palette';
import { loadImage, prepareIcon, prepareTextBackground } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/background.svg';
import transparentPng from '../../resources/images/transparent.png';
// import clusterSvg from '../../resources/images/cluster.svg';
// import animalSvg from '../../resources/images/icon/animal.svg';
// import bicycleSvg from '../../resources/images/icon/bicycle.svg';
// import boatSvg from '../../resources/images/icon/boat.svg';
// import busSvg from '../../resources/images/icon/bus.svg';
// import carSvg from '../../resources/images/icon/car.svg';
// import craneSvg from '../../resources/images/icon/crane.svg';
// import defaultSvg from '../../resources/images/icon/default.svg';
// import helicopterSvg from '../../resources/images/icon/helicopter.svg';
// import offroadSvg from '../../resources/images/icon/offroad.svg';
// import personSvg from '../../resources/images/icon/person.svg';
// import pickupSvg from '../../resources/images/icon/pickup.svg';
// import planeSvg from '../../resources/images/icon/plane.svg';
// import scooterSvg from '../../resources/images/icon/scooter.svg';
// import shipSvg from '../../resources/images/icon/ship.svg';
// import trainSvg from '../../resources/images/icon/train.svg';
// import tramSvg from '../../resources/images/icon/tram.svg';
// import trolleybusSvg from '../../resources/images/icon/trolleybus.svg';
// import truckSvg from '../../resources/images/icon/truck.svg';
// import vanSvg from '../../resources/images/icon/van.svg';
// import tractorOnline from '../../resources/images/trackon-icons/marker_tractor_online.png';
// import bikeOnline from '../../resources/images/trackon-icons/marker_motorcycle_online.png';
import bikeImg from '../../resources/images/trackon-icons/marker_motorcycle.png';
import carImg from '../../resources/images/trackon-icons/marker_car.png';
import busImg from '../../resources/images/trackon-icons/marker_bus.png';
import scooterImg from '../../resources/images/trackon-icons/marker_scooter.png';
import tractorImg from '../../resources/images/trackon-icons/marker_tractor.png';
import truckImg from '../../resources/images/trackon-icons/marker_truck.png';
import offroadImg from '../../resources/images/trackon-icons/marker_offroad.png';
import nameTag from '../../resources/images/nametag.png';


export const mapIcons = {
  // animal: animalSvg,
  // bicycle: bicycleSvg,
  // boat: boatSvg,
  // bus: busSvg,
  // car: carSvg,
  // crane: craneSvg,
  // default: defaultSvg,
  // helicopter: helicopterSvg,
  // motorcycle: bikeOnline,
  // offroad: offroadSvg,
  // person: personSvg,
  // pickup: pickupSvg,
  // plane: planeSvg,
  // scooter: scooterSvg,
  // ship: shipSvg,
  // tractor: tractorOnline,
  // train: trainSvg,
  // tram: tramSvg,
  // trolleybus: trolleybusSvg,
  // truck: truckSvg,
  // van: vanSvg,
  bus: busImg,
  car: carImg,
  tractor: tractorImg,
  motorcycle: bikeImg,
  scooter: scooterImg,
  offroad: offroadImg,
  truck: truckImg,
};

export const mapImages = {};

export default async () => {
  // const nameTag = await loadImage(nameTag);
  const background = await loadImage(backgroundSvg);
  const transparent = await loadImage(transparentPng);
  mapImages.nameTag = await prepareTextBackground(await loadImage(nameTag));
  mapImages.background = await prepareIcon(background);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  // mapImages.stop = await prepareIcon(await loadImage(stopPNG));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    ['positive', 'negative', 'transparent', 'neutral'].forEach((color) => {
      if (color === 'positive' || color === 'negative' || color === 'negative') {
        results.push(loadImage(mapIcons[category]).then((icon) => {
          mapImages[`${category}-${color}`] = prepareIcon(background, icon, palette.colors[color]);
        }));
      } else {
        results.push(loadImage(mapIcons[category]).then((icon) => {
          mapImages[`${category}-${color}`] = prepareIcon(transparent, icon, palette.colors[color]);
        }));
      }
    });
    await Promise.all(results);
  }));
};
