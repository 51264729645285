/* eslint-disable */
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  CardHeader,
  IconButton,
  // Avatar,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ReactSpeedometer from 'react-d3-speedometer';
import './StatusCard.css';
import CloseIcon from '@mui/icons-material/Close';
import ReplayIcon from '@mui/icons-material/Replay';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SpeedIcon from '@mui/icons-material/Speed';
import KeyIcon from '@mui/icons-material/Key';
import AdjustIcon from '@mui/icons-material/Adjust';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import ExploreIcon from '@mui/icons-material/Explore';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CellTowerIcon from '@mui/icons-material/CellTower';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';
import altitudeIcon from '../resources/images/altitude.svg'
import statusIcon from '../resources/images/status.svg'
import blockedSvg from '../resources/images/disable-engine.svg'
import MyLocationIcon from '@mui/icons-material/MyLocation';
import RouterIcon from '@mui/icons-material/Router';

import { useTranslation } from '../common/components/LocalizationProvider';
import { formatStatus, formatTime, formatSpeed, formatBoolean, formatCoordinate, formatCourse, formatDistance, onlySpeed } from '../common/util/formatter';
import RemoveDialog from '../common/components/RemoveDialog';
import PositionValue from '../common/components/PositionValue';
// import dimensions from '../common/theme/dimensions';
import { useDeviceReadonly, useReadonly } from '../common/util/permissions';
import usePersistedState from '../common/util/usePersistedState';
import usePositionAttributes from '../common/attributes/usePositionAttributes';
import { devicesActions } from '../store';
import { useCatch } from '../reactHelper';
import { mapIcons } from '../map/core/preloadImages';
import { width } from '@mui/system';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: dimensions.popupMaxWidth,
    [theme.breakpoints.up('lg')]: {
      width: '68vw',
    },
  },
  negative: {
    color: theme.palette.colors.negative,
  },
  icon: {
    width: '35px',
    height: '35px',
    marginRight: '4px',
    borderRadius: '50%',
    padding: '2px',
    // filter: 'brightness(0) invert(1)',
  },
  online: {
    width: '35px',
    height: '35px',
    marginRight: '4px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: theme.palette.colors.positive,
  },
  offline: {
    width: '35px',
    height: '35px',
    marginRight: '4px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: theme.palette.colors.negative,
  },
  headerActions: {
    display: 'flex',
  },
  // table: {
  //   '& .MuiTableCell-sizeSmall': {
  //     paddingLeft: theme.spacing(0.5),
  //     paddingRight: theme.spacing(0.5),
  //   },
  // },
  table: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: 0,
    height: 181,
    gap: 15,
    marginLeft: 15,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      flexWrap: 'noWrap',
      justifyContent: 'flex-start',
      marginLeft: 0,
      marginTop: 15,
    },
  },
  cardContents: {
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  tableInCard: {
    // width: 1000,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginTop: 'auto',
      flexDirection: 'column',
      height: 200,
      overflowY: 'scroll',
      paddingBottom: 16,
      alignItems: 'center',
    },

  },
  speedMeter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#f3f3f3',
    height: 181,
  },
  address: {
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px',
    textAlign: 'left',
    width: 256,
    margin: '10px 5px 0 5px',
    overflow: 'hidden',
    justifyContent: 'space-between',
    gap: 5,
    borderTop: '0.2px solid #a3a3a3',
    borderWidth: 0.2,
  },
  // addressText: {
  //   width: 240,
  // },
  noGreyCell: {
    borderBottom: 'none',
    display: 'flex',
    padding: '8px 16px 8px 10px',
    height: 36.2,
    overflow: 'hidden',
  },
  greyCell: {
    backgroundColor: 'lightgrey',
    borderBottom: 'none',
    display: 'flex',
    padding: '8px 16px 8px 10px',
    height: 36.2,
    overflow: 'hidden',
  },
  key: {
    width: 96,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 100
    }
  },
  value: {
    width: 170,
    [theme.breakpoints.down('sm')]: {
      width: 170
    }
  },
  totalDkey: {
    width: 126,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 120
    }
  },
  totalDValue: {
    width: 140,
    [theme.breakpoints.down('sm')]: {
      width: 150
    }
  },
  actions: {
    // justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 0,
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'row',
      alignSelf: 'center',
    },
  },
  iconName: {
    fontSize: 15,
  },
  statusIcon: {
    fontSize: 20,
    height: 20,
    width: 20,
    marginRight: 5,
    color: '#6F6F6F',
    filter: 'invert(45%) sepia(1%) saturate(2076%) hue-rotate(15deg) brightness(95%) contrast(90%)',
  },
}));


const StatusRow = ({ name, content, row, align, icon, isTotalDistance }) => {
  const classes = useStyles();

  return (

    <div className={row}>
      <Typography className={isTotalDistance ? classes.totalDkey : classes.key} variant="body2">{icon}{name}</Typography>
      <Typography className={isTotalDistance ? classes.totalDValue : classes.value} variant="body2" align={align ? align : 'right'} color="textSecondary">{content}</Typography>
    </div>
  );
};

const StatusCard = ({ deviceId, onClose }) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.up('sm'));
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();

  const readonly = useReadonly();
  const deviceReadonly = useDeviceReadonly();

  const device = useSelector((state) => state.devices.items[deviceId]);
  const position = useSelector((state) => state.positions.items[deviceId]);
  // console.log(position)

  const positionAttributes = usePositionAttributes(t);
  const [positionItems] = usePersistedState('positionItems', ['speed', 'address', 'totalDistance', 'course', 'deviceTime', 'latitude']);

  const [anchorEl, setAnchorEl] = useState(null);

  const [removing, setRemoving] = useState(false);

  const handleRemove = useCatch(async (removed) => {
    if (removed) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      } else {
        throw Error(await response.text());
      }
    }
    setRemoving(false);
  });

  const addressFormatter = (address) => {
    if (address && address.length > 18) {
      return (address.slice(0, 18) + '...');
    } else {
      return address;
    }
  };

  return (
    <>
      {device && (
        <Card elevation={3} className="card">
          <CardHeader style={{ padding: 0, marginBottom: 16, }}
            avatar={(
              // <Avatar>
              <img className={device.status === 'online' ? classes.online : device.status === 'offline' ? classes.offline : classes.icon} src={mapIcons[device.category || 'default']} alt="" />
              // </Avatar>
            )}
            action={(
              <div className={classes.headerActions}>
                {small && <div><CardActions classes={{ root: classes.headerActions }} disableSpacing>
                  <IconButton
                    color="secondary"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    disabled={!position}
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => navigate('/replay')}
                    disabled={!position}
                  >
                    <ReplayIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                    disabled={readonly}
                  >
                    <PublishIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => navigate(`/settings/device/${deviceId}`)}
                    disabled={deviceReadonly}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => setRemoving(true)}
                    disabled={deviceReadonly}
                    className={classes.negative}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions></div>}
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            title={device.name}
            subheader={formatStatus(device.status, t)}
          />
          <div className={classes.cardContents}>
            {position && (
              <CardContent className={classes.tableInCard}>
                <div className={classes.speedMeter}>
                  {/* <Typography variant='h6' align='center'>Speed</Typography> */}
                  <div style={{ height: 120 }}>
                    <ReactSpeedometer
                      minValue={0}
                      maxValue={200}
                      value={onlySpeed(position.speed, 'kmh')}
                      needleColor="steelblue"
                      width={190}
                      height={120}
                      customSegmentStops={[0, 60, 100, 150, 200]}
                      segmentColors={['limegreen', 'gold', 'tomato', 'firebrick']}
                      needleHeightRatio={0.7}
                      needleTransitionDuration={4000}
                      needleTransition="easeElastic"
                      currentValueText={formatSpeed(position.speed, 'kmh', t)}
                      labelFontSize={10}
                      customSegmentLabels={[
                        {
                          text: '',
                          position: 'INSIDE'
                        },
                        {
                          text: '',
                          position: 'INSIDE'
                        },
                        {
                          text: '',
                          position: 'INSIDE'
                        },
                        {
                          text: '',
                          position: 'INSIDE'
                        },
                      ]}
                      paddingHorizontal={0}
                      paddingVertical={0}
                      ringWidth={30}
                      valueTextFontSize={18}
                    /></div>
                  {/* <StatusRow name={<LocationOnIcon fontSize='small' style={{ color: 'black' }} />} align='left' row={classes.address} content={position.address ? (position.address) : 'N/A'} /> */}
                  <p className={classes.address}>
                    <LocationOnIcon fontSize='small' style={{ alignSelf: 'flex-start' }} />
                    <span className={classes.addressText}>{position.address ? (position.address) : 'N/A'} </span>
                  </p>
                </div>
                <div className={classes.table}>
                  {/* <TableBody> */}
                  {/* {positionItems.filter((key) => position.hasOwnProperty(key) || position.attributes.hasOwnProperty(key)).map((key) => (
                      <StatusRow
                        key={key}
                        name={positionAttributes[key].name}
                        content={(
                          <PositionValue
                            position={position}
                            property={position.hasOwnProperty(key) ? key : null}
                            attribute={position.hasOwnProperty(key) ? null : key}
                          />
                        )}
                      />
                    ))}
                  </TableBody> */}
                  <div>
                    <StatusRow icon={<WatchLaterIcon className={classes.statusIcon} />} name={'Time'} row={classes.greyCell} content={position.fixTime !== undefined ? formatTime(position.fixTime) : 'N/A'} />
                    <StatusRow icon={<MyLocationIcon className={classes.statusIcon} />} name={'Latitude'} row={classes.noGreyCell} content={position.latitude !== undefined ? formatCoordinate('longitude', position.latitude, 'ddm') : 'N/A'} />
                    <StatusRow icon={<MyLocationIcon className={classes.statusIcon} />} name={'Longitude'} row={classes.greyCell} content={position.longitude !== undefined ? formatCoordinate('longitude', position.longitude, 'ddm') : 'N/A'} />
                    <StatusRow icon={<CheckCircleIcon className={classes.statusIcon} />} name={'Valid'} row={classes.noGreyCell} content={position.valid !== undefined ? formatBoolean(position.valid, t) : 'N/A'} />
                    <StatusRow icon={<AdjustIcon className={classes.statusIcon} />} name={'Accuracy'} row={classes.greyCell} content={position.accuracy !== undefined ? position.accuracy : 'N/A'} />
                  </div>
                  <div>
                    <StatusRow icon={<img src={altitudeIcon} className={classes.statusIcon} />} name={'Altitude'} row={classes.greyCell} content={position.altitude !== undefined ? position.altitude : 'N/A'} />
                    <StatusRow icon={<SpeedIcon className={classes.statusIcon} />} name={'Speed'} row={classes.noGreyCell} content={position.speed !== undefined ? formatSpeed(position.speed, 'kmh', t) : 'N/A'} />
                    <StatusRow icon={<ExploreIcon className={classes.statusIcon} />} name={'Course'} row={classes.greyCell} content={position.cours !== undefined ? formatCourse(position.course) : 'N/A'} />
                    <StatusRow icon={<img src={statusIcon} className={classes.statusIcon} />} name={'Status'} row={classes.noGreyCell} content={position.attributes.status ? position.attributes.status : 'N/A'} />
                    <StatusRow icon={<LocationOnIcon className={classes.statusIcon} />} name={'Address'} row={classes.greyCell} content={position.address !== undefined ? addressFormatter(position.address) : 'N/A'} />
                  </div>
                  <div>
                    <StatusRow icon={<RouterIcon className={classes.statusIcon} />} name={'Protocol'} row={classes.greyCell} content={position.protocol !== undefined ? position.protocol : 'N/A'} />
                    <StatusRow icon={<KeyIcon className={classes.statusIcon} />} name={'Ignition'} row={classes.noGreyCell} content={position.attributes.ignition !== undefined ? formatBoolean(position.attributes.ignition, t) : 'N/A'} />
                    <StatusRow icon={<BatteryChargingFullIcon className={classes.statusIcon} />} name={'Charge'} row={classes.greyCell} content={position.attributes.charge !== undefined ? formatBoolean(position.attributes.charge, t) : 'N/A'} />
                    <StatusRow icon={<img src={blockedSvg} className={classes.statusIcon} />} name={'Blocked'} row={classes.noGreyCell} content={position.attributes.blocked !== undefined ? formatBoolean(position.attributes.blocked, t) : 'N/A'} />
                    <StatusRow icon={<BatteryFullIcon className={classes.statusIcon} />} name={'Battery'} row={classes.greyCell} content={position.attributes.batteryLevel !== undefined ? position.attributes.batteryLevel + '%' : 'N/A'} />
                  </div>
                  <div>
                    <StatusRow icon={<CellTowerIcon className={classes.statusIcon} />} name={'RSSI'} row={classes.greyCell} content={position.attributes.rssi !== undefined ? position.attributes.rssi : 'N/A'} />
                    <StatusRow icon={<AddRoadIcon className={classes.statusIcon} />} name={'Distance'} row={classes.noGreyCell} content={position.attributes.distance !== undefined ? formatDistance(position.attributes.distance, 'km', t) : 'N/A'} />
                    <StatusRow icon={<AddRoadIcon className={classes.statusIcon} />} name={'Total Distance'} isTotalDistance={true} row={classes.greyCell} content={position.attributes.totalDistance !== undefined ? formatDistance(position.attributes.totalDistance, 'km', t) : 'N/A'} />
                    <StatusRow icon={<MinorCrashIcon className={classes.statusIcon} />} name={'Motion'} row={classes.noGreyCell} content={position.attributes.motion !== undefined ? formatBoolean(position.attributes.motion, t) : 'N/A'} />
                    <StatusRow icon={<WatchLaterIcon className={classes.statusIcon} />} name={'Hours'} row={classes.greyCell} content={position.attributes.hours} />
                  </div>
                </div>
              </CardContent>
            )}
            {!small && <CardActions classes={{ root: classes.actions }} disableSpacing>
              <IconButton
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                disabled={!position}
              >
                <InfoIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate('/replay')}
                disabled={!position}
              >
                <ReplayIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate(`/settings/command-send/${deviceId}`)}
                disabled={readonly}
              >
                <PublishIcon />
              </IconButton>
              <IconButton
                onClick={() => navigate(`/settings/device/${deviceId}`)}
                disabled={deviceReadonly}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => setRemoving(true)}
                disabled={deviceReadonly}
                className={classes.negative}
              >
                <DeleteIcon />
              </IconButton>
            </CardActions>}
          </div>
        </Card>
      )}
      {position && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => navigate(`/position/${position.id}`)}><Typography color="secondary">{t('sharedShowDetails')}</Typography></MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${position.latitude}%2C${position.longitude}`}>{t('linkGoogleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`http://maps.apple.com/?ll=${position.latitude},${position.longitude}`}>{t('linkAppleMaps')}</MenuItem>
          <MenuItem component="a" target="_blank" href={`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${position.latitude}%2C${position.longitude}`}>{t('linkStreetView')}</MenuItem>
        </Menu>
      )}
      <RemoveDialog
        open={removing}
        endpoint="devices"
        itemId={deviceId}
        onResult={(removed) => handleRemove(removed)}
      />
    </>
  );
};

export default StatusCard;
