/*eslint-disable */
import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  IconButton, Paper, Slider, Toolbar, Tooltip, Typography, LinearProgress
} from '@mui/material';

import ReactSpeedometer from 'react-d3-speedometer';
import makeStyles from '@mui/styles/makeStyles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MapView from '../map/core/MapView';
import MapRoutePath from '../map/MapRoutePath';
import MapPositions from '../map/MapPositions';
import { formatTime, onlySpeed } from '../common/util/formatter';
import ReportFilter from '../reports/components/ReportFilter';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useCatch } from '../reactHelper';
import MapReplayPosition from '../map/MapReplayPosition';
import ReplayLiveRoute from '../map/ReplayLiveRoute';
import MapReplayIcon from '../map/MapReplayIcon';
// import MapReplayMarker from '../map/MapReplayMarker';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: 3,
    left: 0,
    top: 0,
    margin: theme.spacing(1.5),
    width: theme.dimensions.drawerWidthDesktop,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: 0,
    },
  },
  title: {
    flexGrow: 1,
  },
  deviceName: {
    fontWeight: 'bold',
  },
  address: {
    width: '100%',
    fontSize: 14,
    height: 18,
    margin: 0,
    marginBottom: 5,
  },
  slider: {
    width: '100%',
  },
  speed: {
    // margin: 'auto',
    marginTop: 3,
    width: '30%',
    height: 77,
    // backgroundColor: 'black',
    borderRight: '1px solid #d3d3d3',
  },
  controls: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #d3d3d3',
  },
  replay: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  controlComp: {
    width: '70%',
    paddingLeft: 15,
    paddingRight: 15,
    boxSizing: 'border-box',
  },
  formControlLabel: {
    height: '100%',
    width: '100%',
    paddingRight: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1),
    },
  },
}));

const TimeLabel = ({ children, open, value }) => (
  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
    {children}
  </Tooltip>
);

const ReplayPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const timerRef = useRef();

  const defaultDeviceId = useSelector((state) => state.devices.selectedId);

  const [positions, setPositions] = useState([]);
  const [index, setIndex] = useState(0);
  const [selectedDeviceId, setSelectedDeviceId] = useState(defaultDeviceId);
  const [expanded, setExpanded] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [show, setShow] = useState(false)
  // console.log(playing);

  const deviceName = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.name;
      }
    }
    return null;
  });
  const status = useSelector((state) => {
    if (selectedDeviceId) {
      const device = state.devices.items[selectedDeviceId];
      if (device) {
        return device.status;
      }
    }
    return null;
  });
  const onClick = useCallback((positionId) => {
    navigate(`/position/${positionId}`);
  }, [navigate]);

  useEffect(() => {
    if (!playing && positions.length > 0) {
      timerRef.current = setInterval(() => {
        setIndex((index) => index + 1);
      }, 700);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [playing, positions]);

  useEffect(() => {
    if (index >= positions.length - 1) {
      clearInterval(timerRef.current);
      setPlaying(false);
    }
  }, [index, positions]);

  const handleSubmit = useCatch(async ({ deviceId, from, to, headers }) => {
    setSelectedDeviceId(deviceId);
    const query = new URLSearchParams({ deviceId, from, to });
    const response = await fetch(`/api/positions?${query.toString()}`, { headers });
    if (response.ok) {
      setIndex(0);
      const positions = await response.json();
      setPositions(positions);
      if (positions.length) {
        setExpanded(false);
      } else {
        throw Error(t('sharedNoData'));
      }
    } else {
      throw Error(await response.text());
    }
  });
  const handleShow = (bool) => {
    setShow(bool);
  };
  return (
    <div className={classes.root}>
      {show && index === 0 && positions.length !== 0 && <LinearProgress />}
      <MapView>
        <MapRoutePath positions={positions} />
        {index < positions.length && (
          <div>
            <MapReplayIcon positions={[positions[index]]} showStatus={status} onClick={onClick} />
            <MapReplayPosition position={[positions[index]]} />
          </div>

        )}
        <ReplayLiveRoute positions={positions} index={index} />

      </MapView>
      <div className={classes.sidebar}>
        <Paper elevation={3} square>
          <Toolbar>
            <IconButton edge="start" sx={{ mr: 2 }} onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>{t('reportReplay')}</Typography>
            {!expanded && (
              <IconButton edge="end" onClick={() => setExpanded(true)}>
                <SettingsIcon />
              </IconButton>
            )}
          </Toolbar>
        </Paper>
        <Paper className={classes.content} square>
          {!expanded ? (
            <>
              <Typography className={classes.deviceName} variant="body1" align="center">{deviceName}</Typography>
              <Typography variant="subtitle1" className={classes.address} align="center">{positions[index].address}</Typography>
              <Typography variant="subtitle1" className={classes.address} align="center">{formatTime(positions[index].fixTime)}</Typography>

              {/* <Slider
                className={classes.slider}
                max={positions.length - 1}
                step={null}
                marks={positions.map((_, index) => ({ value: index }))}
                value={index}
                onChange={(_, index) => setIndex(index)}
                valueLabelDisplay="auto"
                valueLabelFormat={(i) => (i < positions.length ? formatTime(positions[i]) : '')}
                ValueLabelComponent={TimeLabel}
              /> */}
              <div className={classes.controls}>
                {/* {`${index + 1}/${positions.length}`} */}
                <div className={classes.speed}>
                  <ReactSpeedometer
                    minValue={0}
                    maxValue={200}
                    value={onlySpeed(positions[index].speed, 'kmh')}
                    needleColor="steelblue"
                    width={100}
                    height={100}
                    customSegmentStops={[0, 60, 100, 150, 200]}
                    segmentColors={['limegreen', 'gold', 'tomato', 'firebrick']}
                    customSegmentLabels={['', '', '', '']}
                    needleHeightRatio={0.4}
                    needleTransitionDuration={4000}
                    needleTransition="easeElastic"
                    currentValueText={`${Math.floor(positions[index].speed)} KM/H`}
                    labelFontSize="10px"
                    ringWidth={10}
                    valueTextFontSize="10px"
                  />
                </div>
                <div className={classes.controlComp}>
                  <Slider
                    className={classes.slider}
                    max={positions.length - 1}
                    step={null}
                    marks={positions.map((_, index) => ({ value: index }))}
                    value={index}
                    onChange={(_, index) => setIndex(index)}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(i) => (i < positions.length ? formatTime(positions[i].fixTime) : '')}
                    ValueLabelComponent={TimeLabel}
                  />
                  <div className={classes.replay}>
                    <IconButton onClick={() => setIndex((index) => index - 1)} disabled={!playing || index <= 0}>
                      <FastRewindIcon />
                    </IconButton>
                    <IconButton onClick={() => setPlaying(!playing)} disabled={index >= positions.length - 1}>
                      {!playing ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <IconButton onClick={() => setIndex((index) => index + 1)} disabled={!playing || index >= positions.length - 1}>
                      <FastForwardIcon />
                    </IconButton>
                    {/* {formatTime(positions[index].fixTime)} */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ReportFilter handleSubmit={handleSubmit} handleShow={handleShow} fullScreen showOnly />
          )}
        </Paper>
      </div>
    </div>
  );
};

export default ReplayPage;
